
import { computed, defineComponent, onMounted, onUpdated, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import PaymentTypeSelect from "@/components/catalogs-select/PaymentTypeSelect.vue";
import { useStore } from "vuex";

interface FormCatalog {
  id?: number;
  name: string;
  description: string;
  payment_type_id: string;
  payment_number: string;
  frequency: string;
  first_payment_deferral: string;
}

export default defineComponent({
  name: "PaymentTypesDetailsForm",
  components: {
    PaymentTypeSelect,
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    entityId: String,
    paymentType: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const paymentTypesData = computed(() => store.getters.PaymentTypes);
    const submitButton = ref<HTMLElement | null>(null);
    const form = ref<FormCatalog>({
      name: "",
      description: "",
      payment_type_id: "",
      payment_number: "",
      frequency: "",
      first_payment_deferral: "",
    });
    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      if (props.entityId) {
        store.commit("setLoadingStatus", true);
        ApiService.get("/api/paymentTypeDetails/" + props.entityId).then(
          ({ data }) => {
            form.value = { ...data };
            store.commit("setLoadingStatus", false);
          }
        );
      }
    });

    const createElement = async (resetForm) => {
      try {
        store.commit("setLoadingStatus", true);
        const data = {
          name: form.value.name,
          description: form.value.description,
          payment_type_id: form.value.payment_type_id,
          payment_number: form.value.payment_number,
          frequency: form.value.frequency,
          first_payment_deferral: form.value.first_payment_deferral,
        };
        const resp = (await ApiService.post("/api/paymentTypeDetails", data))
          .data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        store.commit("setLoadingStatus", false);
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (resetForm) => {
      store.commit("setLoadingStatus", true);
      const data = {
        name: form.value.name,
        description: form.value.description,
        payment_type_id: form.value.payment_type_id,
        payment_number: form.value.payment_number,
        frequency: form.value.frequency,
        first_payment_deferral: form.value.first_payment_deferral,
      };
      ApiService.put("/api/paymentTypeDetails/" + props.entityId, data)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      }
    };

    const showEvent = (eventData) => {
      // console.log(eventData);
    };

    return {
      showEvent,
      paymentTypesData,
      submitButton,
      form,
      onSumbit,
      isNameRequired,
      updateElement,
      createElement,
    };
  },
});
