
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "PaymentTypeSelect",
  props: {
    payment_type_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:payment_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const paymentTypesData = computed(() => store.getters.PaymentTypes);
    const inputPaymentType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });
    const isPaymentTypeRequired = (value) => {
      if (props.required && !value) {
        return t("rpaymenttype");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "paymentType",
      isPaymentTypeRequired
    );

    const getPaymentType = () => {
      if (!paymentTypesData.value.length) {
        store.commit("setLoading", false);
        ApiService.get("/api/paymentTypes/lists").then(({ data }) => {
          store.commit("setPaymentTypes", data.payment_types);
          inputPaymentType.value.list = data.payment_types;
          inputPaymentType.value.options = data.payment_types;
        });
      } else {
        inputPaymentType.value.list = paymentTypesData.value.slice();
        inputPaymentType.value.options = paymentTypesData.value.slice();
      }
      const index = inputPaymentType.value.options.findIndex(
        (x) => x.name === "Todos"
      );
      if (props.multiselect) {
        if (index === -1) {
          inputPaymentType.value.options.splice(0, 0, {
            id: 0,
            name: "Todos",
          });
        }
      } else if (index !== -1) {
        inputPaymentType.value.options.splice(0, 1);
      }
    };
    const selectPaymentType = (query) => {
      if (query !== "") {
        inputPaymentType.value.loading = true;
        setTimeout(() => {
          inputPaymentType.value.loading = false;
          inputPaymentType.value.options = inputPaymentType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputPaymentType.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/paymentTypes/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              store.commit("addPaymentType", data.payment_types);
              inputPaymentType.value.list = paymentTypesData.value;
              inputPaymentType.value.options = data.payment_types;
            });
          }
        }, 200);
      } else {
        inputPaymentType.value.options = paymentTypesData.value;
      }
    };

    watch(
      () => props.payment_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        getPaymentType();
      }, 1000);
    });

    return {
      element_id,
      inputPaymentType,
      errorMessage,
      selectPaymentType,
    };
  },
};
